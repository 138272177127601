import React from "react";
import "../ConsultCard.scss";
import "./ConsultCardB.scss";
import { ReactComponent as CloseIcon } from "../../../../static/icons/close.svg";
// import { ReactComponent as Image } from "../../../../static/popup-2.svg";
import Image from "../../../../static/popup-2.svg";

interface ConsultCardBProps {
  onClose?: any;
}

const ConsultCardB = ({ onClose }: ConsultCardBProps) => {
  return (
    <div className="consult-card" id="type-b">
      <button className="close-button" onClick={onClose}>
        <CloseIcon />
      </button>
      <div className="card-image-wrapper">
        <img src={Image} className="card-image" />
      </div>
      <div className="card-main">
        <div className="card-title">결합 B형.</div>
        <div className="card-desc">
          법인설립 후 세무서비스를 이용할 경우,<br/>
          법인설립 수수료를 100% D/C 해드려요
        </div>
        <div className="card-desc ps">{`(*세무기장료 별도 발생)`}</div>
      </div>
      <div className="card-sub-wrapper">
        <div className="card-sub">
          <small>비상주 사무실</small>
          <div className="row">
            <p>
              <strong>15,000원</strong> / 월
            </p>
            <span>월 1만원 할인</span>
          </div>
          <label>
            <span>25,000</span>원 / 월
          </label>
        </div>
        <div className="card-sub">
          <small>법인설립</small>
          <div className="row">
            <p>
              <strong>0원</strong>
            </p>
            <span>100% 할인</span>
          </div>
          <label>
            <span>550,000</span>원
          </label>
        </div>
      </div>
      <div className="card-bottom">연 682,000원 혜택</div>
    </div>
  );
};

export default ConsultCardB;
