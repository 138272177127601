import React from "react";
import "./Terms.scss";
import { Header } from "../components/base/Header";

const Terms = () => {
  return (
    <>
      <Header />
      <div className="terms-container">
        <div className="section">
          <h1>개인정보 취급방침</h1>
          <p>
            밸런스 스페이스(이하 “회사”)는 개인정보의 보호와 관련하여
            ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’ 및 ‘개인정보
            보호법’ 등 관련법 상의 개인정보 보호 규정을 준수하고 있음을 알린다.
          </p>
        </div>

        <div className="section">
          <h2>제 1 조 (총칙)</h2>
          <p>
            ① 개인정보란 회원 개인에 관한 정보를 칭한다. 정보에 포함되어있는
            성명, 주민등록번호 등의 사항에 의하여 개인을 식별할 수 있는 정보를
            지칭한다.
            <br />② 회사는 개인정보처리방침을 변경하는 경우에는 시행의 시기,
            변경된 내용을 홈페이지를 통하여 공개할 예정이다.
          </p>
        </div>

        <div className="section">
          <h2>제 2 조 (개인정보 수집범위)</h2>
          <p>
            회사의 서비스를 이용할 경우 다음의 정보를 입력해야 한다.
            <br />
            -서비스 이용 시 수집하는 개인정보의 범위: 성명, 전자우편 주소,
            주민등록번호, 계좌번호, 전화번호, 주소, 홈택스 및 CMS 수임 동의
          </p>
        </div>

        <div className="section">
          <h2>제 3 조 (개인정보 수집에 대한 동의)</h2>
          <p>
            회사의 개인정보취급방침 또는 이용약관의 내용에 대해 ‘동의’ 버튼을
            체크하면 개인정보 수집에 대해 동의한 것으로 본다.
          </p>
        </div>

        <div className="section">
          <h2>제 4 조 (개인정보의 열람 및 정정)</h2>
          <p>
            회원은 언제든지 등록되어 있는 회원의 개인정보를 열람하거나 정정할 수
            있다. 개인정보 열람 및 정정을 하고자 할 경우에는 직접 열람 또는
            정정하거나, 회사에 전화 또는 전자우편으로 연락하면 회사는 지체 없이
            조치한다.
          </p>
        </div>

        <div className="section">
          <h2>제 5 조 (개인정보 수집, 이용 및 제공에 대한 동의 철회)</h2>
          <p>
            개인정보의 수집, 이용, 제공에 대해 회원은 동의한 내용을 언제든지
            철회할 수 있다. 동의철회는 직접 철회하거나, 회사에 연락하면 관리자는
            즉시 개인정보의 삭제 등 필요한 조치를 한다.
          </p>
        </div>

        <div className="section">
          <h2>제 6 조 (개인정보의 수집목적 및 이용목적)</h2>
          <p>
            다음과 같은 목적을 위하여 개인정보를 수집하고 있다.
            <br />
            1. 주소, 전화번호, 전자우편 주소 : 서비스 제공에 있어서의 원활한
            커뮤니케이션 수단 확보, 중요 고지사항의 전달, 회사가 제공하는 모든
            상품/서비스, 제휴사에서 제공하는 상품/서비스 및 회사가 타사로부터
            의뢰받은 타사의 상품/서비스에 대해 전화, SMS, MMS, E-mail, 우편 등을
            통해 정보의 전송 제공 목적
            <br />2 기타 홈택스 및 CMS 관련 정보 : 수임 동의 및 원활한 회계 세무
            대행서비스를 위한 수단, 신규 서비스 개발 및 이용자별 특화서비스
            제공을 위한 통계분석
          </p>
        </div>

        <div className="section">
          <h2>제 7 조 (목적 외 사용 및 제3자에 대한 제공 및 공유)</h2>
          <p>
            ① 이용자의 개인정보를 제 6 조에서 고지한 범위 내에서 사용하며, 동
            범위를 초과하여 이용하거나 타인 또는 타 기업·기관에 제공하지 않는다.
            <br />
            ② 고지 및 동의방법은 홈페이지를 통해 최소 30일 이전부터 고지한다.
            <br />③ 다음은 예외로 한다.
            <div style={{ marginTop: 16 }}>
              <p className="indent">
                1. 관계 법령에 의하여 수사상의 목적으로 관계기관으로부터의
                요구가 있을 경우
              </p>
              <p className="indent">
                2. 통계작성·학술연구나 시장조사를 위하여 특정 개인을 식별할 수
                없는 형태로 광고주·협력사나 연구단체 등에 제공하는 경우
              </p>
              <p className="indent">
                3. 기타 관계 법령에서 정한 절차에 따른 요청이 있는 경우
              </p>
              <p className="indent">
                4. 회사는 예외 사항에서도 관계 법령에 의하거나 수사기관의 요청에
                의해 정보를 제공한 경우에는 이를 당사자에게 고지하는 것을
                원칙으로 한다. 그러나 회사는 법률상의 근거에 의해 부득이하게
                고지를 하지 못할 수도 있다.
              </p>
            </div>
          </p>
        </div>

        <div className="section">
          <h2>제 8 조 (개인정보의 처리 및 보유 기간)</h2>
          <p>
            ① 회사가 회원으로부터 개인정보를 수집하는 경우 그 보유 기간 및 이용
            기간은 회원이 직접 서비스를 신청한 시점부터 해지하겠다는 의사 표시를
            하여 해지가 처리된 시점까지로 한다.
            <br />② 상법 및 ‘전자상거래 등에서 소비자 보호에 관한 법률’ 등 관련
            법령의 규정에 의하여 다음과 같이 거래 관련 권리 의무 관계의 확인
            등을 이유로 일정기간 보유하여야 할 필요가 있을 경우에는 일정기간
            보유한다.
          </p>
          <p className="indent">
            - 계약 또는 청약철회 등에 관한 기록 : 5년
            <br />- 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
          </p>
        </div>

        <div className="section">
          <h2>제 9 조 (회원 정보의 보호 및 관리)</h2>
          <p>
            ① 회사의 개인정보는 본인만이 직접 관리할 수 있다. 정보 변경은 반드시
            본인 확인 과정을 거쳐 정보를 편집할 수 있도록 처리되어 있으므로
            반드시 회원은 직접 관리해야 한다.
            <br />② 회사는 회원의 개인정보를 보호하기 위하여 운영상의 시정 또는
            개선을 요구하는 사항이 있는 경우 이를 시정 또는 개선하고 기타 필요한
            조치를 취하도록 최선을 다한다.
          </p>
        </div>

        <div className="section">
          <h2>제 10 조 (회원의 권리와 의무)</h2>
          <p>
            ① 회원은 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를
            예방해야 한다. 회원이 입력한 부정확한 정보로 인해 발생하는 사고의
            책임은 자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우
            이용 자격이 상실될 수 있다.
            <br />② 회원은 개인정보를 보호받을 권리와 함께 스스로를 보호하고
            타인의 정보를 침해하지 않을 의무를 가지고 있다. 개인정보가 유출되지
            않도록 조심해야 하고 타인의 개인정보를 훼손하지 않도록 유의해야
            한다. 만약 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을
            훼손할 시에는 ‘정보통신망 이용 촉진 및 정보보호 등에 관한 법률’ 등에
            의해 처벌받을 수 있다.
          </p>
        </div>

        <div className="section">
          <h2>제 11 조 (고지의 의무)</h2>
          <p>
            이 개인정보보호정책은 정부의 정책 또는 보안기술의 변경에 따라 내용의
            추가·삭제 및 수정 이 있을 시에는 개정 최소 7일 전부터 홈페이지를
            통해 고지한다.
          </p>
        </div>

        <div className="section">
          <h2>제 12 조 (개인정보관리책임자)</h2>
          <p>
            ① 밸런스 스페이스는 개인정보의 누출 및 오·남용 등으로 인한 피해를
            방지하기 위하여 개인정보취급자를 최소한의 인원으로 제한하고 있으며,
            개인정보 관리에 관한 실질적인 책임을 가진 대표자를
            개인정보관리책임자로 지정하고 있다.
            <br />② 밸런스 스페이스의 개인정보 관리책임자는 다음과 같다.
            <br />
            성명 : 양영선
            <br />
            소속 : (주)유닛블랙랩스
            <br />
            전화 : 1644-8681
          </p>
        </div>
      </div>
    </>
  );
};

export default Terms;
