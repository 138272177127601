import React from "react";
import "./Terms.scss";
import { Header } from "../components/base/Header";

const Privacy = () => {
  return (
    <>
      <Header />
      <div className="terms-container">
        <div className="section">
          <h1>밸런스 스페이스 이용약관</h1>
          <p>
            밸런스 스페이스 이용약관은 공정거래위원회의 「표준이용약관」을
            기반으로 작성되었음을 알려드립니다.
          </p>
        </div>

        <div className="section">
          <h2>제 1 조 (목적)</h2>
          <p>
            본 약관은 밸런스 스페이스(이하 “회사”라 한다)와 밸런스 스페이스가
            제공하는 서비스(이하 “서비스”라 한다), 이용회원(이하 “회원”라 한다)
            간에 이용조건 및 절차, 정산, 이용자와 회사의 권리, 의무, 책임사고와
            기타 필요한 사항에 관한 사항을 규정함을 목적으로 한다.
          </p>
        </div>

        <div className="section">
          <h2>제 2 조 (약관의 명시, 효력 및 변경)</h2>
          <p>
            ① 회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 서비스 초기
            화면에 게시한다.
            <br />
            ② 서비스 이용은 회원이 본 약관 내용에 동의하는 경우, 서비스 제공
            행위 및 회원의 서비스 사용 행위에 본 약관이 우선적으로 적용 된다.
            <br />
            ③ 회사는 본 약관을 사전 고지 없이 변경할 수 있고, 변경된 약관은
            홈페이지 내에 공지하거나 전자우편을 통하여 회원에게 공지하며,
            의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 본다.
            <br />④ 회원이 개정약관에 동의하지 않을 경우 이용계약을 해지할 수
            있다.
          </p>
        </div>

        <div className="section">
          <h2>제 3 조 (회원의 가입 및 관리)</h2>
          <p>
            ① 서비스 이용자가 이 약관을 읽고 "동의" 버튼을 누르거나 "확인" 등에
            체크한 경우 동의한 것으로 간주한다.
            <br />② 서비스 이용을 위한 회원가입은 서비스 약관에 동의한 후,
            회사가 정한 온라인 회원가입 신청서에 회원 필수사항을 입력하고,
            "확인"을 하는 방법으로 한다. 단, 회사가 필요하다고 인정하는 경우
            회원에게 별도의 서류를 제출하도록 할 수 있다.
          </p>
        </div>

        <div className="section">
          <h2>제 4 조 (회원의 유보 및 거절)</h2>
          <p>
            ① 이용계약은 신청자가 온라인 등으로 회사가 제공하는 서비스 양식에
            따라 이용자 정보 사항을 기입한 후 “동의” 버튼을 누르거나 “확인” 등에
            체크한 경우 이 약관에 동의한 것으로 간주한다.
          </p>
          <p className="indent">
            1. 계약의 성립 시기는 회사의 승낙이 회원에게 도달한 시점으로 한다.
            <br />
            2. 회원은 등록사항에 변경이 있는 경우, 즉시 전자우편 및 기타
            방법으로 회사에 그 변경사항을 알려야 한다.
          </p>
          <p>
            ② 회사는 다음 각 호에 해당하는 경우에는 이용계약을 취소할 수 있다.
          </p>
          <p className="indent">
            1. 신청자가 이전에 회원자격을 상실한 적이 있는 경우
            <br />
            2. 신청 시 등록한 내용을 허위로 기재하거나 누락이 있는 경우
            <br />
            3. 타 인의 명의를 도용하거나, 타인의 서비스 이용을 방해할 목적으로
            이용할 경우
          </p>
          <p>
            ③ 회사는 다음 각 호에 해당하는 경우 그 사유가 해결될 때까지
            이용계약을 유보할 수 있다.
          </p>
          <p className="indent">
            1. 서비스 관련 제반 용량이 부족할 경우
            <br />
            2. 기술상 장애 사유가 있어서 불가피하게 이용을 제한해야 할 경우
            <br />
            3. 14세 미만의 아동이 개인정보제공에 대한 동의를 부모 등
            법정대리인으로부터 받지 않은 경우
            <br />
            4. 기타 회사가 관련법령 등을 기준으로 하여 명백하게 사회질서 및
            미풍양속에 반할 우려가 있음을 인정하는 경우
            <br />
          </p>
        </div>

        <div className="section">
          <h2>제 5 조 (회원의 관리책임)</h2>
          <p>
            ① 회원은 서비스 이용을 위한 회원 개인정보 관리에 대한 책임
            불이행으로 인해 과실이 발생하는 모든 불이익에 대한 책임을 부담한다.
            단, 회사의 고의 과실로 야기된 경우는 회사가 책임을 부담한다.
            <br />
            ② 회원은 회원 개인정보를 도난 및 제3자의 사용을 인지한 경우에는 즉시
            이를 회사에 통보하여 회사의 안내를 따라야 한다.
            <br />
            ③ 회원은 개인정보 사항이 변경되었을 경우 즉시 이를 회사에 통보하여
            변경하여야 한다. 회원이 회원정보를 변경하지 않아 손해가 발생한 경우
            회사는 책임을 부담하지 않는다.
            <br />
          </p>
        </div>

        <div className="section">
          <h2>제 6 조 (개인정보의 수집 등)</h2>
          <p>
            회사는 서비스를 제공하기 위하여 관련 법령의 규정에 따라 회원으로부터
            필요한 개인정보를 수집한다.
            <br />
            1. 생년월일, 거주지역 및 연락처 등<br />
            2. 우편/경품 수신 주소, 취미, 관심사 등<br />
            3. 서비스별 뉴스레터 수신 여부 등<br />
            4. 기타 회사가 인정하는 사항
          </p>
        </div>

        <div className="section">
          <h2>제 7 조 (서비스 이용)</h2>
          <p>
            ① 서비스 이용은 회사의 서비스 사용승낙 직후부터 가능하며, 유료
            서비스의 경우 회사가 요금 납입을 확인한 직후부터 가능하다.
          </p>
        </div>

        <div className="section">
          <h2>제 8 조 (서비스 이용시간)</h2>
          <p>
            ① 서비스 이용시간은 업무상 또는 기술상 특별한 지정이 없는 한
            연중무휴로 1일 24시간 이용을 원칙으로 한다. 다만, 서비스의 종류 및
            내용에 따라 일부의 서비스는 별도의 이용시간을 정할 수 있으며, 이
            경우 해당 내용을 서비스 화면에 공지하도록 한다.
            <br />② 제1항의 이용시간에도 불구하고 통신장애, 서비스개발,
            정기점검, 긴급조치 등 불가피한 사유에 한해 서비스 제공이 일정기간
            동안 제한되거나 중단될 수 있다.
          </p>
        </div>

        <div className="section">
          <h2>제 9 조 (서비스의 중지 및 정보의 저장과 사용)</h2>
          <p>
            ① 회원은 서비스 이용 중에 보관되거나 전송된 메시지 및 기타 통신
            메시지 등의 내용이 국가의 비상사태, 정전, 회사의 관리 범위 외의
            서비스 설비 장애 및 기타 불가항력에 의하여 보관되지 못하였거나
            삭제된 경우, 전송되지 못한 경우 및 기타 통신 데이터의 손실이 있을
            경우에 회사는 관련 책임을 부담하지 아니한다.
            <br />② 회사가 정상적인 서비스 제공의 어려움으로 인하여 일시적으로
            서비스를 중지하여야 할 경우에는 서비스 중지 고지 후 서비스를 중지할
            수 있으며, 이 기간 동안 회원이 고지 내용을 인지하지 못한 데 대하여
            회사는 책임을 부담하지 아니한다. 부득이한 사정이 있을 경우 사전 고지
            기간은 감축되거나 생략될 수 있다. 또한 위 서비스 중지에 의하여
            서비스에 보관되거나 전송된 메시지 및 기타 통신 메시지 등의 내용이
            보관되지 못하였거나 삭제된 경우, 전송되지 못한 경우 및 기타 통신
            데이터의 손실이 있을 경우에 대하여도 회사는 책임을 부담하지
            아니한다.
            <br />③ 회사는 사전 고지 후 서비스를 일시적으로 수정, 변경 및 중단할
            수 있으며, 이에 대하여 회원 또는 제3자에게 어떠한 책임도 부담하지
            아니한다.
            <br />④ 회사는 회원이 본 약관의 내용에 위배되는 행동을 한 경우,
            임의로 서비스 사용을 제한 및 중지할 수 있다.
          </p>
        </div>

        <div className="section">
          <h2>제 10 조 (정보 제공 및 홍보물 게재)</h2>
          <p>
            ① 회사는 서비스를 운영함에 있어서 각종 정보를 서비스에 게재하는 방법
            등으로 회원에게 제공할 수 있다.
            <br />② 회사는 서비스에 적절하다고 판단되거나 활용 가능성이 있는
            홍보물을 게재할 수 있다.
          </p>
        </div>

        <div className="section">
          <h2>제 11 조 (게시물의 저작권)</h2>
          <p>
            ① 회원이 게시한 게시물의 내용에 대한 권리는 회원 본인에게 있다.
            <br />② 회사는 게시된 내용을 사전 통지 없이 편집, 이동할 수 있는
            권리를 보유하며, 다음의 경우 사전 통지 없이 삭제할 수 있다.
            <p className="indent">
              1. 본 서비스 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고
              판단되는 게시물을 게시한 경우
              <br />
              2. 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를
              손상시키는 내용인 경우
              <br />
              3. 공공질서 및 미풍양속에 위반되는 내용인 경우
              <br />
              4. 범죄적 행위에 결부된다고 인정되는 내용일 경우
              <br />
              5. 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우
              <br />
              6. 기타 관계 법령에 위배되는 경우
              <br />
            </p>
            <br />③ 회원의 게시물이 타인의 저작권을 침해함으로써 발생하는 민,
            형사상의 책임은 전적으로 회원이 부담하여야 한다.
          </p>
        </div>

        <div className="section">
          <h2>제 12 조 (회원의 행동규범 및 서비스 이용제한)</h2>
          <p>
            ① 회원이 제공하는 정보의 내용이 허위인 것으로 판명되거나, 그러하다고
            의심할 만한 합리적인 사유가 발생할 경우 회사는 회원의 서비스 사용을
            일부 또는 전부 중지할 수 있으며, 이로 인해 발생하는 불이익에 대해
            책임을 부담하지 아니한다.
            <br />
            ② 회원이 서비스를 통하여 게시, 전송, 입수하였거나 전자메일, 기타
            다른 수단에 의하여 게시, 전송 또는 입수한 모든 형태의 정보에
            대하여는 회원이 모든 책임을 부담하며 회사는 어떠한 책임도 부담하지
            아니한다.
            <br />③ 회사는 제공한 서비스가 아닌 회원 또는 기타 유사업체가
            제공하는 서비스의 내용상의 정확성, 완전성 및 질에 대하여 보장하지
            않는다. 따라서 회사는 회원이 위 내용을 이용함으로 인하여 입게 된
            모든 종류의 손실이나 손해에 대하여 책임을 부담하지 아니한다.
            <br /> ④ 회원은 본 서비스를 통하여 다음과 같은 행동을 하지 않는 데
            동의 한다.
            <p className="indent">
              1. 타인의 ID와 비밀번호를 도용하는 행위
              <br />
              2. 저속, 음란, 모욕적, 위협적이거나 타인의 프라이버시를 침해할 수
              있는 내용을 전송, 게시, 게재, 전자메일 또는 기타의 방법으로
              전송하는 행위
              <br />
              3. 서비스를 통하여 전송된 내용의 출처를 위장하는 행위
              <br />
              4. 법률, 계약에 의하여 이용할 수 없는 내용을 게시, 게재, 전자메일
              또는 기타의 방법으로 전송하는 행위
              <br />
              5. 타인의 특허, 상표, 영업비밀, 저작권, 기타 지적재산권을 침해하는
              내용을 게시, 게재, 전자메일 또는 기타의 방법으로 전송하는 행위
              <br />
              6. 회사 승인을 받지 아니한 광고, 판촉물, 정크메일, 스팸, 행운의
              편지, 피라미드 조직, 기타 다른 형태의 권유를 게시, 게재, 전자메일
              또는 기타의 방법으로 전송하는 행위
              <br />
              7. 다른 회원의 개인정보를 수집 또는 저장하는 행위
              <br />
            </p>
            ⑤ 회사는 이용자가 본 약관을 위배했다고 판단되면 서비스와 관련된 모든
            정보를 이용자의 동의 없이 삭제할 수 있다.
          </p>
        </div>

        <div className="section">
          <h2>제 13 조 (회사의 의무 및 준수사항)</h2>
          <p>
            ① 회사는 특별한 사정이 없는 한 회원이 신청한 서비스 제공은 결재 이후
            시점부터 이용할 수 있도록 한다.
            <br />
            ② 회사는 시스템 및 서버점검 및 업그레이드, 통신두절, 기타
            불가항력적인 사고 등 특별한 사정이 없는 한 본 약관이 정한 바에 따라
            지속적, 안정적인 서비스를 제공할 의무가 있다. ③ 회사는 서비스 이용과
            관련한 내용을 회원에게 설명해야 하며, 서비스 중 관련 민원을 신속히
            해결하도록 최선의 노력을 다하여야 하고, 서비스를 이용 중인 이용자가
            불이익을 당하지 않도록 최선의 노력을 다하여야 한다.
            <br />
            ④ 회사는 서비스 이용계약의 성립 및 서비스 이행을 위한 필요한
            최소한의 개인신용정보만을 수집하며, 회원이 서비스 이용신청서를
            통하여 동의한 개인신용정보의 이용 범위를 초과하여 이용하거나
            제3자에게 제공, 위탁하는 경우에는 회원에게 별도의 동의를 받아야
            한다.
            <br />
            ⑤ 회사는 서비스와 연관된 각종 정보가 유출되거나 부당하게 이용되지
            않도록 정보 보호 및 보안에 선량한 의무를 다하여야 한다.
            <br />⑥ 회원은 언제든지 자신의 개인정보를 열람할 수 있고, 회사 또는
            정보관리 책임자에게 잘못된 정보에 대한 정정을 요청할 수 있다. 회사는
            회사로부터 정보 정정의 요청이 있는 경우 그 오류가 시정될 때까지 당해
            개인정보를 이용하지 않는다.
          </p>
        </div>

        <div className="section">
          <h2>제 14조 (회사의 소유권)</h2>
          <p>
            ① 회사가 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크,
            로고, 디자인, 서비스명칭, 정보 및 상표 등과 관련된 지적재산권 및
            기타 권리는 회사에 소유권이 있다.
            <br />② 회원은 회사가 명시적으로 승인한 경우를 제외하고는 전항의
            소정의 각 재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포,
            제작, 양도, 재 라이선스, 담보권 설정 행위, 상업적 이용 행위를 할 수
            없으며, 제3자로 하여금 이와 같은 행위를 하도록 허락할 수 없다.
          </p>
        </div>

        <div className="section">
          <h2>제 15 조 (양도금지)</h2>
          <p>
            회원은 서비스의 이용권한, 기타 이용계약 상 지위를 타인에게 양도,
            증여할 수 없다.
          </p>
        </div>

        <div className="section">
          <h2>제 16 조 (이용요금의 납입)</h2>
          <p>
            ① 회원은 회사가 제공하는 유료서비스를 이용하는 경우 이용대금을
            납부한 후 서비스를 이용하는 것을 원칙으로 한다.
            <br />② 회사가 제공하는 유료서비스에 대한 이용요금의 결제 방법은
            핸드폰결제, 신용카드결제, 일반전화결제, 계좌이체, 무통장입금 등이다.
            <br />③ 회사는 이용요금의 납입을 위하여 필요한 회원의 개인정보를
            추가적으로 요구할 수 있으며, 회원은 회사가 요구하는 개인정보를
            정확하게 제공하여야 한다. 회사는 회원이 허위로 또는 부정확하게
            제공한 개인정보로 인하여 회원에게 발생하는 손해에 대하여 회사의
            고의·과실이 없는 한 회원의 손해를 배상할 책임을 부담하지 않는다.
          </p>
        </div>

        <div className="section">
          <h2>제 17 조 (재판관할)</h2>
          <p>
            회사는 발생한 서비스 이용에 관한 분쟁에 대하여는 대한민국 법을
            적용하며, 본 분쟁으로 인한 소는 대한민국의 법원에 제기한다.
          </p>
        </div>

        <div className="section">
          <h2>제 18 조 (개인정보관리책임자)</h2>
          <p>
            ① 회사는 개인정보의 누출 및 오·남용 등으로 인한 피해를 방지하기
            위하여 개인정보취급자를 최소한의 인원으로 제한하고 있으며, 개인정보
            관리에 관한 실질적인 책임을 가진 대표자를 개인정보관리책임자로
            지정하고 있다.
            <br />
            ② 회사의 개인정보관리책임자는 다음과 같다.
            <br />
            성명 : 양영선
            <br />
            소속 : (주)유닛블랙랩스
            <br />
            전화 : 1644-8681
            {`<본 방침은 2022.2.1. 부터 시행됩니다.>`}
          </p>
        </div>
      </div>
    </>
  );
};

export default Privacy;
