export const CUSTOMERS_REVIEW = [
  {
    id: 1,
    name: 'kyo**** 23.06.27',
    location: '지점 : 서초 / 이용기간 : 12개월',
    status: '재구매',
    review: '응대가 친절하고 절차가 간편해 계약이 하루 만에 되었습니다! 가격도 최저가에다 사업자등록도 무료로 대신 등록해주니 너무 편했습니다.'
  },
  {
    id: 2,
    name: 'ghdtk**** 23.03.03',
    location: '지점 : 영등포 / 이용기간 : 6개월',
    status: 'none',
    review: '비상주 서비스가 정말 좋습니다. 대만족입니다.'
  },
  {
    id: 3,
    name: 'dks**** 23.03.06',
    location: '지점 : 송파 / 이용기간 : 6개월',
    status: '한달사용기',
    review: '소개 받아서 계약했습니다. 사무실을 구하기에는 아직 버거웠는데 우편물 서비스도 제공되고 좋네요!'
  },
  {
    id: 4,
    name: 'djaqod**** 23.03.31',
    location: '지점 : 용인 / 이용기간 : 6개월',
    status: 'none',
    review: '가격은 말할 것도 없고 친절하고 빨라서 좋았습니다!'
  },
  {
    id: 5,
    name: 'qyqy*** 23.04.15',
    location: '지점 : 영등포 / 이용기간 : 6개월',
    status: 'none',
    review: '좋은 조건에 계약했습니다. 무료로 사업자등록 업무 대행도 해 주시고 감동입니다 ^^'
  },
  {
    id: 6,
    name: 'kanko*** 23.04.27',
    location: '지점 : 강남 / 이용기간 : 6개월',
    status: '재구매',
    review: '6개월 이용하고, 또 재계약 했습니다! 판매자의 주소지 신원 보호가 되는 점이 정말 만족스럽습니다!'
  },
  {
    id: 7,
    name: 'si**** 23.05.06',
    location: '지점 : 송파 / 이용기간 : 1년',
    status: 'none',
    review: '급하게 사업자가 필요한 상황이었는데 빠르게 처리해주셔서 감사합니다! 1년 계약했지만 앞으로도 계속 연장할 것 같아요!'
  },
  {
    id: 8,
    name: 'kore**** 23.06.03',
    location: '지점 : 송파 / 이용기간 : 6개월',
    status: '재구매',
    review: '우편물 도착하면 문자로 먼저 알려주시고, 무엇보다 합리적인 가격이 가장 큰 장점인 것 같아요!'
  },
  {
    id: 9,
    name: 'win**** 23.05.27',
    location: '지점 : 용인 / 이용기간 : 1년',
    status: '재구매',
    review: '1년 또 연장했습니다. 계약 절차가 너무 간편하고 사업장 주소지도 깔끔해서 좋아요!'
  },
];

export default {
  CUSTOMERS_REVIEW
}