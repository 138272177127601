import React from "react";
import "../ConsultCard.scss";
import "./ConsultCardA.scss";
import { ReactComponent as CloseIcon } from "../../../../static/icons/close.svg";
// import { ReactComponent as Image } from "../../../../static/popup-1.svg";
import Image from "../../../../static/popup-1.svg";

interface ConsultCardAProps {
  onClose?: any;
}

const ConsultCardA = ({ onClose }: ConsultCardAProps) => {
  return (
    <div className="consult-card" id="type-a">
      <div>
        <button className="close-button" onClick={onClose}>
          <CloseIcon />
        </button>
        <div className="card-image-wrapper">
          <img src={Image} className="card-image" />
        </div>
        <div className="card-main">
          <div className="card-title">결합 A형.</div>
          <div className="card-desc">
            세무서비스를 함께 이용할 경우,<br/>
            비상주 사무실을 D/C 해드려요
          </div>
          <div className="card-desc ps">{`(*세무기장료 별도 발생)`}</div>
        </div>
        <div className="card-sub">
          <small>비상주 사무실</small>
          <div className="row">
            <p>
              <strong>15,000원</strong> / 월
            </p>
            <span>월 1만원 할인</span>
          </div>
          <label>
            <span>25,000</span>원 / 월
          </label>
        </div>
      </div>

      <div className="card-bottom">연 120,000원 혜택</div>
    </div>
  );
};

export default ConsultCardA;
