import React, { useCallback, useEffect, useState } from "react";
import "./index.scss";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";

import { ConsultSelectB, RequestDialog } from "../../components/consult";
import { ReactComponent as ArrowLeft } from "../../static/icons/arrow-left.svg";
import {
  CounselingField,
  requestState,
  UsePeriod,
} from "../../store/RequestStore";
import { useLocalStorage } from "../../shared/utill";

const Consult2 = () => {
  const [searchParams] = useSearchParams();
  const [request, setRequest] = useRecoilState(requestState);
  const [persistedRequest, setPersistedRequest] = useLocalStorage(
    "request",
    request
  );
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();

  const onOpen = () => {
    setDialogVisible(true);
  };

  useEffect(() => {
    const hasEvent = searchParams.get("has_event") === "true";
    const eventType = searchParams.get("event_type");
    if (hasEvent) {
      let usePeriod = undefined;
      switch (eventType) {
        case "0_12":
          usePeriod = UsePeriod._12;
          break;
        case "0_24":
          usePeriod = UsePeriod._24;
          break;
      }
      setRequest({
        ...request,
        counselingType: CounselingField.DEFAULT,
        usePeriod,
      });
      onOpen();
    }
  });

  useEffect(() => {
    if ((window as any).fbq) {
      (window as any).fbq("track", "Optimization2");
    }
  }, []);
  const onClose = () => {
    const hasEvent = searchParams.get("has_event") === "true";
    if (hasEvent) {
      const eventType = searchParams.get("event_type");
      const search = location.search.replace(
        /&has_event=true&event_type=(.*)/,
        ""
      );
      switch (eventType) {
        case "0_12":
        case "0_24":
          window.location.href = "https://balancespace-event.co.kr/" + search;
      }
    } else {
      setDialogVisible(false);
    }
  };

  const onSubmitSuccess = useCallback(() => {
    const hasEvent = searchParams.get("has_event") === "true";
    if (hasEvent) {
      const eventType = searchParams.get("event_type");
      const search = location.search.replace(
        /&has_event=true&event_type=(.*)/,
        ""
      );
      switch (eventType) {
        case "0_12":
        case "0_24":
          window.location.href = "https://balancespace-event.co.kr/" + search;
      }
    } else {
      navigate(`/${location.search}`);
    }
  }, []);

  const selectOption = (period: UsePeriod) => {
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.text = `
      var across_adn_complete_contain = new fn_across_adn_contain();
      var across_adn_complete_param = [];
      across_adn_complete_param = {"ut":"Complete","ui":"107325","uo":"types1"};
      across_adn_complete_contain.init(across_adn_complete_param);
    `;

    document.body.appendChild(script);

    setRequest({ ...request, usePeriod: period });
    setPersistedRequest(request);
    onOpen();
  };

  return (
    <div className="consult" id="consult2">
      <div className="consult-content">
        <div className="back-button" onClick={() => navigate(-1)}>
          <ArrowLeft />
        </div>
        <div>
          <h1 className="title">비상주 사무실 이용기간을 선택해주세요</h1>
          <p className="ghost">(VAT 별도)</p>
          <ul className="card-wrapper">
            <ConsultSelectB
              title="3개월"
              price={
                request.counselingType === CounselingField.DEFAULT
                  ? "60,000원"
                  : "50,000원"
              }
              ogPrice="100,000원"
              onClick={() => selectOption(UsePeriod._3)}
            />
            <ConsultSelectB
              title="6개월"
              price={
                request.counselingType === CounselingField.DEFAULT
                  ? "40,000원"
                  : "30,000원"
              }
              ogPrice="100,000원"
              onClick={() => selectOption(UsePeriod._6)}
            />
            <ConsultSelectB
              title="12개월"
              price={
                request.counselingType === CounselingField.DEFAULT
                  ? "30,000원"
                  : "20,000원"
              }
              ogPrice="100,000원"
              onClick={() => selectOption(UsePeriod._12)}
            />
            <ConsultSelectB
              title="24개월"
              price={
                request.counselingType === CounselingField.DEFAULT
                  ? "25,000원"
                  : "15,000원"
              }
              ogPrice="100,000원"
              onClick={() => selectOption(UsePeriod._24)}
            />
            <ConsultSelectB
              title="36개월"
              price={
                request.counselingType === CounselingField.DEFAULT
                  ? "22,000원"
                  : "12,000원"
              }
              ogPrice="100,000원"
              best
              onClick={() => selectOption(UsePeriod._36)}
            />
          </ul>
          <p className="small">*선릉점, 신논현점 금액 별도 문의</p>
          {request.counselingType !== CounselingField.DEFAULT ? (
            <p className="bold">세무기장료 별도 발생</p>
          ) : (
            <></>
          )}
        </div>
        <RequestDialog
          visible={dialogVisible}
          onSubmitSuccess={onSubmitSuccess}
          onClose={onClose}
        />
      </div>
    </div>
  );
};

export default Consult2;
